import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import BottomTabsBar from 'src/components/common/bottomTabsBar';
import HorizontalScroll from 'src/components/common/horizontalScroll';
import images from 'src/assets/images';
import { useAuth } from 'src/contexts/authContext';
import { fetchUserCauses, fetchPopularCauses, fetchCauses } from 'src/services/cause/api';
import UserCauseCard from 'src/components/cause/userCauseCard';
import CauseCard from 'src/components/cause/causeCard';
import SliderWrapper from 'src/components/slider/sliderWrapper';

type Cause = {
  id: string;
  name: string;
  mission: string;
  goalAmount: number;
  currentAmount: number;
  causeProfileUrl: string;
  imageUrl: string;
  buyersCount: number;
  profilePictures: string[];
  CategoryName: string;
};

type PopularCause = Cause;

const Causes: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [selectedCategory, setSelectedCategory] = useState<string>('All Causes');
  const [userCauses, setUserCauses] = useState<Cause[]>([]);
  const [popularCauses, setPopularCauses] = useState<PopularCause[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const [causes, setCauses] = useState<Cause[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>(''); // Added for search functionality
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false); // Search state
  const categoryRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleCategoryClick = (category: string) => {
    setSelectedCategory(category);
    if (category === 'All Causes') {
      if (contentRef.current) {
        contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    } else {
      const categoryElement = categoryRefs.current[category];
      if (categoryElement) {
        categoryElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleCauseClick = (causeId: string) => {
    navigate(`/cause-detail/${causeId}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userCausesData: any = await fetchUserCauses({ userId: user.id });
        setUserCauses(userCausesData);

        const popularCausesData: any = await fetchPopularCauses();
        setPopularCauses(popularCausesData);

        const causesData: any = await fetchCauses();
        setCauses(causesData);

        const uniqueCategories: any = [
          ...new Set(causesData.map((cause: Cause) => cause.CategoryName)),
        ];
        setCategories(['All Causes', ...uniqueCategories]);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
  }, [user.id]);

  // Filtered causes based on search input
  const filteredCauses = causes.filter(
    (cause) =>
      cause.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cause.mission.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <div className="px-4 mt-4">
        <div className="flex items-center justify-between">
          <h1 className={`text-2xl font-bold ${isSearchActive ? 'hidden' : ''}`}>Causes</h1>
          <div className="flex items-center w-full">
            {!isSearchActive ? (
              <img
                src={images.search_icon_2}
                alt="Search Icon"
                className="absolute right-8 w-5 h-5 cursor-pointer"
                onClick={() => setIsSearchActive(true)}
              />
            ) : (
              <div className="relative flex items-center w-full">
                <img
                  src={images.search_icon_3}
                  alt="Search Icon"
                  className="absolute left-3 w-5 h-5 text-gray-400"
                />
                <input
                  type="text"
                  placeholder="Search causes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full py-2 pl-10 pr-4 rounded-2xl bg-[#F2F2F3] text-[#1E1E1E] border-2 border-transparent outline-none focus:border-[#3D86FF]"
                  onBlur={() => {
                    if (searchTerm === '') setIsSearchActive(false);
                  }}
                  autoFocus
                />
              </div>
            )}
          </div>
        </div>

        {!isSearchActive && (
          <div className="py-1">
            <HorizontalScroll>
              <div className="text-sm flex space-x-2">
                {categories.map((category, index) => (
                  <button
                    key={index}
                    onClick={() => handleCategoryClick(category)}
                    className={`py-1 px-4 rounded-full whitespace-nowrap ${
                      selectedCategory === category
                        ? 'bg-[#3D86FF] text-white'
                        : 'bg-[#E5E5E5] text-[#808283]'
                    }`}
                  >
                    {category}
                  </button>
                ))}
              </div>
            </HorizontalScroll>
          </div>
        )}
      </div>

      <div ref={contentRef} className="flex-grow px-4 overflow-y-auto overflow-x-hidden scrollbar-hide pb-20">
        {/* Search results */}
        {isSearchActive && searchTerm && (
          <div className="mt-12 w-full">
            <div className="grid gap-4 mt-4">
              {filteredCauses.length > 0 ? (
                filteredCauses.map((cause, index) => (
                  <CauseCard
                    key={index}
                    causeWallPaper={cause.imageUrl}
                    causeLogo={cause.causeProfileUrl}
                    causeName={cause.name}
                    causeMission={cause.mission}
                    buyersCount={cause.buyersCount || 0}
                    onClick={() => handleCauseClick(cause.id)}
                  />
                ))
              ) : (
                <p className="text-center text-[#787B7E] mt-10">No causes found</p>
              )}
            </div>
          </div>
        )}

        {/* My Causes */}
        {!isSearchActive && (
          <>
            <div className="w-full">
              <h2 className="text-lg font-bold text-gray-900">My Causes</h2>
              <div className="relative w-full mt-2">
                {userCauses.length > 0 ? (
                  <SliderWrapper
                    items={userCauses}
                    renderItem={(userCause, index) => (
                      <UserCauseCard
                        key={index}
                        causeWallPaper={userCause.imageUrl}
                        causeLogo={userCause.causeProfileUrl}
                        causeName={userCause.name}
                        causeMission={userCause.mission}
                        raisedAmount={userCause.currentAmount}
                        goalAmount={userCause.goalAmount}
                        buyersCount={userCause.buyersCount || 0}
                        buyersImages={userCause.profilePictures || []}
                        onClick={() => handleCauseClick(userCause.id)}
                      />
                    )}
                  />
                ) : (
                  <p className="text-center text-[#787B7E] mt-10">No user causes available</p>
                )}
              </div>
            </div>

            {/* Popular Causes */}
            <div className="mt-12 w-full">
              <h2 className="text-lg font-bold text-gray-900">Popular Causes</h2>
              <div className="grid gap-4 mt-4">
                {popularCauses.length > 0 ? (
                  popularCauses.map((popularCause, index) => (
                    <CauseCard
                      key={index}
                      causeWallPaper={popularCause.imageUrl}
                      causeLogo={popularCause.causeProfileUrl}
                      causeName={popularCause.name}
                      causeMission={popularCause.mission}
                      buyersCount={popularCause.buyersCount || 0}
                      onClick={() => handleCauseClick(popularCause.id)}
                    />
                  ))
                ) : (
                  <p className="text-center text-[#787B7E] mt-10">No popular causes available</p>
                )}
              </div>
            </div>

            {/* Causes by Category */}
            {categories.slice(1).map((category) => (
              <div key={category} ref={(el) => (categoryRefs.current[category] = el)} className="mt-12 w-full">
                <h2 className="text-lg font-bold text-gray-900">{category}</h2>
                <div className="grid gap-4 mt-4">
                  {causes
                    .filter((cause) => cause.CategoryName === category)
                    .map((cause, index) => (
                      <CauseCard
                        key={index}
                        causeWallPaper={cause.imageUrl}
                        causeLogo={cause.causeProfileUrl}
                        causeName={cause.name}
                        causeMission={cause.mission}
                        buyersCount={cause.buyersCount || 0}
                        onClick={() => handleCauseClick(cause.id)}
                      />
                    ))}
                </div>
              </div>
            ))}
          </>
        )}

        <div className="mt-12 w-full">
          <div className="w-full h-40 bg-gray-100 rounded-lg"></div>
        </div>
      </div>

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto">
        <BottomTabsBar />
      </div>
    </>
  );
};

export default Causes;
