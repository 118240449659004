import React, {useEffect} from 'react';
import Button from 'src/components/common/button';
import { useAuth } from 'src/contexts/authContext';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import FundcoinRewardCard from 'src/components/chooseDonation/fundcoinRewardCard';
const OnboardingComplete: React.FC = () => {
  const { user, donationAmount, resetNewUser, coinsEarned, appDonationAmount, updateUser }: any = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
   if(user.id) {
     updateUser({ userId: user.id});
   }
  }, [user.id]);
  const handleSeeOffers = () => {
  resetNewUser();
  navigate('/rewards');
};

  return (
    <div className="p-4 flex flex-col items-center justify-start min-h-screen bg-white"> {/* Adjusted the top padding */}
      <div className="text-center mt-1">
        <img src={images.payment_success_icon} alt="Success" className="w-32 h-32 mx-auto mb-1" /> {/* Replace with your actual image */}
        <h2 className="p-3 text-3xl font-semibold text-[#FF6B6B] mb-2">
          Thank you for your donation, {user?.firstName}!
        </h2>
        <p className="text-lg text-gray-700 mb-8">
          Your donation will help immensely and now we would like to reward you for your efforts...
        </p>
      </div>

      <FundcoinRewardCard
        fundcoinAmount={coinsEarned}
        donationAmount={donationAmount}
        appDonation={appDonationAmount}
      />

      <div className="fixed bottom-0 z-10 w-full max-w-[548px] mx-auto px-4 py-4 bg-white">
        <Button onClick={handleSeeOffers}>See my offers</Button>
      </div>
    </div>
  );
};

export default OnboardingComplete;
