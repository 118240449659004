import React from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileInfo from 'src/components/profile/profileInfo';
import ProfileStats from 'src/components/profile/profileStats';
import DonationSection from 'src/components/profile/donationSection';
import images from 'src/assets/images'; // Ensure the image paths are correct
import { useAuth } from 'src/contexts/authContext';
import { uploadProfileImage } from 'src/services/profile/api'; // Import your API function

const Profile: React.FC = () => {
  const { user, updateUser } = useAuth(); // Assuming setUser is available to update user state after upload
  const navigate = useNavigate();

  const userObject = {
    firstName: 'David',
    lastName: 'Priebe',
    profileImage: images.profile, // Replace with actual path
    joinDate: 'Aug. 2022',
    lifetimeDonations: 100,
    fundcoinsGenerated: 1850,
    donationAmount: '$10',
    fundcoinEarned: 185,
    causeLogo: images.cause_logo, // Replace with actual path
    causeName: 'Hub of Hope'
  };

  const handleBack = () => {
    navigate('/settings'); // Adjust to the correct route
  };

  // Function to handle profile image upload
  const handleUploadClick = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    
    fileInput.onchange = async (event: any) => {
      const file = event.target.files[0];
      if (file) {
        try {
          // Prepare form data
          console.log("file", file);
          const formData = new FormData();
          formData.append('profileImage', file);
          formData.append('userId', user.id); // Ensure userId is sent

          // Call the API to upload the image
          const response: any = await uploadProfileImage(formData);

          if (response) {
            console.log('Profile image uploaded successfully:', response.imageUrl);
            await updateUser({ userId: user.id });

          } else {
            console.error('Failed to upload profile image:');
          }
        } catch (error) {
          console.error('Error uploading profile image:', error);
        }
      }
    };

    fileInput.click(); 
  };

  return (
    <div className="relative">
      <div className="absolute top-6 flex justify-between items-center w-full px-4">
        <img
          src={images.leftarrow}
          alt="Back"
          className="w-4 h-4 cursor-pointer mt-3"
          onClick={handleBack}
        />
        <div className="flex-grow flex justify-center">
          <h1 className="text-lg font-bold mt-2">Profile</h1>
        </div>
      </div>

      <div className="py-12">
        <ProfileInfo
          profileImage={user.profileUrl || images.profile}
          firstName={user.firstName}
          lastName={user.lastName}
          joinDate={user.joinDate}
          onUploadClick={handleUploadClick} // Pass the upload function to ProfileInfo
        />
          
        <ProfileStats
          lifetimeDonations={userObject.lifetimeDonations}
          fundcoinsGenerated={userObject.fundcoinsGenerated}
        />

        <DonationSection
          donationAmount={userObject.donationAmount}
          fundcoinEarned={userObject.fundcoinEarned}
          causeLogo={userObject.causeLogo}
          causeName={userObject.causeName}
        />
      </div>
    </div>
  );
};

export default Profile;
