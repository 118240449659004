import React, { useState, useRef, useEffect } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Import default styles
import images from 'src/assets/images'; // Ensure this path is correct

interface InputFieldProps {
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => void;
  prefix?: string; // New optional prop to handle prefixes like dollar signs
}

const InputField: React.FC<InputFieldProps> = ({ type, placeholder, value, onChange, prefix }) => {
  const [inputType, setInputType] = useState(type);

  const togglePasswordVisibility = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    if (typeof e === 'string') {
      onChange(e); // For PhoneInput, pass the value directly
    } else {
      onChange(e); // For regular input fields, pass the event object
    }
  };

  // Phone Input case
  if (type === 'phone') {
    return (
      <div className="relative w-full mb-6">
        <PhoneInput
          country={'us'}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          inputClass="text-xl" // Increase text size for phone input
        />
      </div>
    );
  }

  return (
    <div className="relative w-full mb-6">
      {/* If prefix exists, render a span for the prefix */}
      {prefix && (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-500">
          {prefix}
        </span>
      )}
      <input
        type={inputType}
        placeholder={placeholder}
        className={`w-full border-2 rounded-input-custom py-2 px-4 text-xl text-gray-500 bg-[rgba(33,45,54,0.06)] border-transparent outline-none focus:border-[#3D86FF] ${prefix ? 'pl-10' : ''}`}
        value={value}
        onChange={handleChange}
      />
      {/* Password toggle visibility */}
      {type === 'password' && (
        <img
          src={inputType === 'password' ? images.hide : images.show}
          alt="Toggle password visibility"
          className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer w-7 h-7"
          onClick={togglePasswordVisibility}
        />
      )}
    </div>
  );
};

export default InputField;
interface OTPInputProps {
  length: number;
  value: string;
  onChange: (value: string) => void;
  onComplete: () => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length, value, onChange, onComplete }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (value.length === length) {
      onComplete();
    }
  }, [value, length, onComplete]);

  useEffect(() => {
    hiddenInputRef.current?.focus(); // Focus the hidden input field for autofill
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newValue = e.target.value;
    const newOtp = value.split('');
    newOtp[index] = newValue;
    onChange(newOtp.join(''));

    if (newValue && index < length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      const newOtp = value.split('');
      newOtp[index] = '';
      onChange(newOtp.join(''));

      if (index > 0) {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  const handlePaste = (e: any) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData('text').slice(0, length);

    if (pasteData.length === length) {
      onChange(pasteData);
      inputRefs.current[length - 1]?.focus();
    } else {
      const newOtp = value.split('');
      for (let i = 0; i < pasteData.length && i < length; i++) {
        newOtp[i] = pasteData[i];
      }
      onChange(newOtp.join(''));

      const nextIndex = pasteData.length;
      if (nextIndex < length) {
        inputRefs.current[nextIndex]?.focus();
      } else {
        inputRefs.current[length - 1]?.focus();
      }
    }
  };

  const handleHiddenInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newOtp = e.target.value.slice(0, length);
    onChange(newOtp);

    // Automatically populate the fields and focus the last one
    for (let i = 0; i < newOtp.length && i < length; i++) {
      inputRefs.current[i]?.focus();
    }
  };

  return (
    <div className="flex space-x-2 justify-center">
      {/* Hidden input for handling iOS autofill */}
      <input
        ref={hiddenInputRef}
        type="tel"
        autoComplete="one-time-code"
        onChange={handleHiddenInputChange}
        className="absolute opacity-0 pointer-events-none"
      />

      {Array.from({ length }).map((_, index) => (
        <input
          key={index}
          type="tel"
          maxLength={1}
          value={value[index] || ''}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          ref={(el) => (inputRefs.current[index] = el)}
          className="w-10 h-12 sm:w-12 sm:h-14 md:w-14 md:h-16 border-2 rounded-lg text-center text-gray-800 bg-[rgba(33,45,54,0.06)] border-transparent outline-none focus:border-[#3D86FF] transition-all"
        />
      ))}
    </div>
  );
};
export { InputField, OTPInput };