import React, { useState } from 'react';
import images from 'src/assets/images';

interface InviteFriendsCardProps {
  referralCode: string;
}

const clientUrl = process.env.REACT_APP_CLIENT_URL || 'https://fundrgiving.com';

const InviteFriendsCard: React.FC<InviteFriendsCardProps> = ({ referralCode }) => {
  const [buttonText, setButtonText] = useState<string>('Copy');
  const inviteLink = `${clientUrl}/signup?referralCode=${referralCode}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(inviteLink);
    setButtonText('Copied!');

    setTimeout(() => {
      setButtonText('Copy');
    }, 5000);
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Join Fundr!',
          text: 'Use my referral link to join Fundr and get a reward of 50 fundrcoins.',
          url: inviteLink,
        });
        console.log('Successful share');
      } catch (error) {
        console.error('Error sharing', error);
      }
    } else {
      alert('Web Share API is not supported in your browser.');
    }
  };

  return (
    <div className="w-full rounded-2xl bg-[#0A1E32] p-4">
      <div className="text-white text-2xl font-semibold mb-2">
        <span style={{ background: 'linear-gradient(to right, #EC4899, #FB923C)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
          Get your friends to join you!
        </span>
      </div>
      <div className="text-lg text-white mb-4">
        You’ll Receive 50 fundcoin for each successful invite.
      </div>

      <div className="flex justify-around items-center mb-4">
        <div className="flex flex-col items-center">
          <div
            className="rounded-full p-6 bg-[#172E46] cursor-pointer"
            onClick={handleShare}
          >
            <img src={images.more_icon} alt="More" className="w-8 h-8" />
          </div>
          <span className="text-xs text-white mt-1">More...</span>
        </div>
      </div>

      <div className="flex items-center justify-between bg-[#102a43] rounded-md px-4 py-2">
        <span className="text-white text-sm truncate">{inviteLink}</span>
        <button
          className="bg-gradient-to-r from-pink-500 to-orange-400 text-white px-3 py-1 rounded-md font-semibold"
          onClick={handleCopy}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default InviteFriendsCard;
