import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import images from 'src/assets/images'; // Make sure to replace with the correct path to your icon

interface ProfileCardProps {
  firstName: string;
  lastName: string;
  profileImage: string;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ firstName, lastName, profileImage }) => {
  const navigate = useNavigate(); // Initialize the navigate hook

  const handleNavigateToProfile = () => {
    navigate('/profile'); // Navigate to the profile page
  };

  return (
    <div className="flex justify-between items-center p-4 bg-[#F2F2F3] rounded-xl">
      <div className="flex items-center">
        <img src={profileImage} alt={`${firstName} ${lastName}`} className="w-16 h-16 rounded-full" />
        <div className="ml-4">
          <p className="text-md text-gray-500">Profile</p>
          <h2 className="text-xl font-bold">{`${firstName} ${lastName}`}</h2>
        </div>
      </div>
      {/* Add the arrow image to the right and onClick handler */}
      <img
        src={images.right_direction_icon}
        alt="Go to Profile"
        className="w-4 h-4 cursor-pointer"
        onClick={handleNavigateToProfile} // Navigate on click
      />
    </div>
  );
};

export default ProfileCard;
