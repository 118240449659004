import React from 'react';
import images from 'src/assets/images'; 

interface RewardInfoProps {
  title: string;
  imageUrl: string;
  categoryName: string;
}

const RewardInfo: React.FC<RewardInfoProps> = ({ title, imageUrl, categoryName }) => {
  return (
    <div className="w-full bg-white rounded-2xl">
      <div className="relative">
        <img src={imageUrl || images.reward_wallpaper} alt={title} className="w-full h-46 object-cover rounded-lg" />
      </div>
      <div className="p-2">
        <h3
          className="text-sm font-normal mb-1"
          style={{
            background: 'linear-gradient(to bottom, #EC4899, #FB923C)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {categoryName || "Category Name"}
        </h3>
        <h3 className="mt-1 text-lg font-bold text-gray-800">{title}</h3>
        <button className="w-full mt-2 bg-gradient-to-r from-pink-500 to-orange-400 text-white py-2 rounded-lg">
          VISIT WEBSITE & USE CODE
        </button>
      </div>
    </div>
  );
};

export default RewardInfo;
