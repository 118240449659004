import React from 'react';
import images from 'src/assets/images';
import { useNavigate, useLocation } from 'react-router-dom';

const BottomTabsBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: 'Home', icon: images.home_icon, path: '/dashboard' },
    { label: 'Rewards', icon: images.reward_icon, path: '/rewards' },
    { label: 'Causes', icon: images.search_icon, path: '/causes' },
    { label: 'Wallet', icon: images.wallet_icon, path: '/wallet' },
    { label: 'Refer', icon: images.refer_icon, path: '/refer' },
  ];

  return (
    <div className="flex justify-around w-full py-4 bg-white">
      {tabs.map((tab, index) => {
        const isSelected = location.pathname === tab.path;

        return (
          <div
            key={index}
            className="flex flex-col items-center"
            onClick={() => navigate(tab.path)}
          >
            <div
              className={`w-7 h-7 mb-1`}
              style={{
                maskImage: `url(${tab.icon})`,
                WebkitMaskImage: `url(${tab.icon})`,
                background: isSelected
                  ? 'linear-gradient(to right, #FB923C, #EC4899)'
                  : '#9DA8B8',
                maskRepeat: 'no-repeat',
                WebkitMaskRepeat: 'no-repeat',
                maskSize: 'contain',
                WebkitMaskSize: 'contain',
                maskPosition: 'center',
                WebkitMaskPosition: 'center',
                width: '28px', 
                height: '28px',
              }}
            />
            <span
              className="text-sm"
              style={{ color: isSelected ? 'black' : '#9DA8B8' }}
            >
              {tab.label}
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default BottomTabsBar;
