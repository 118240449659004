import React from 'react';

interface HorizontalScrollProps {
  children: React.ReactNode;
}

const HorizontalScroll: React.FC<HorizontalScrollProps> = ({ children }) => {
  return (
    <div className="flex overflow-x-auto space-x-4 py-4 scrollbar-hide">
      {children}
    </div>
  );
};

export default HorizontalScroll;
