import React from 'react';

interface LogoutButtonProps {
  onLogout: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
  return (
    <div className="mt-4 bg-[#F2F2F3] rounded-xl">
      <div
        onClick={onLogout}
        className="flex justify-between items-center py-4 px-4 cursor-pointer transition-all duration-200 hover:bg-[#E8ECEE] rounded-lg"
      >
        <span className="text-black font-normal">Logout</span>
      </div>
    </div>
  );
};

export default LogoutButton;
