import React from 'react';
import images from 'src/assets/images';
import { useNavigate } from 'react-router-dom';
import ProgressBar from 'src/components/common/progressBar';
import { useSwipeable } from 'react-swipeable';
const IntroScreenThree: React.FC = () => {
    const navigate = useNavigate();

  const handleNext = () => {
    navigate('/introduction-four');
  };
  const handleBack = () => {
    navigate('/introduction-two'); 
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,  
    onSwipedRight: handleBack, 
    preventScrollOnSwipe: true, 
    trackMouse: true, 
  });

  return (
    <div {...handlers} className="relative w-full h-full flex flex-col items-center justify-center p-4 bg-darkblue">
        <div className="absolute inset-0 bg-cover bg-center rounded-3xl" style={{ backgroundImage: `url(${images.intro_three})` }}></div>
        <div className="absolute bottom-[2vh] z-10 flex flex-col items-center text-center px-6"> 
          <h1 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-orange-400 mb-4">
            Make a consistent<br /><span className="block">impact</span>
          </h1>
          <p className="text-s mb-4" style={{ color: '#8197AD' }}>
             You decide how much you can give each month to support your cause and<br /><span className="block">we give 100% of it to your cause.</span>
          </p>
          <ProgressBar step={1}  />
          <button className="relative py-0.5 px-0.5 rounded-custom text-white bg-transparent border-none mt-8 w-full max-w-md" onClick={handleNext}>
            <span className="absolute inset-0 bg-gradient-to-r from-pink-500 to-orange-400 rounded-custom p-px"></span>
            <span className="relative bg-darkblue rounded-custom block py-3 px-12 font-bold text-lg">Next</span>
          </button>
        </div>
      </div>
  );
};

export default IntroScreenThree;

