import React from 'react';
import RewardInfo from 'src/components/reward/rewardPurchaseModal/rewardInfo';
import RedemptionSteps from 'src/components/reward/rewardPurchaseModal/redemptionSteps';
import ContactSupport from 'src/components/reward/rewardPurchaseModal/contactSupport';
import images from 'src/assets/images';

interface RewardPurchaseCompleteProps {
  onClose: () => void;
  reward: {
    title: string;
    categoryName: string;
    imageUrl: string;
  };
}

const RewardPurchaseComplete: React.FC<RewardPurchaseCompleteProps> = ({ onClose, reward }) => {
  const steps = [
    { number: 1, title: 'Buy Offer with Fundcoin', description: 'You have successfully purchased this offer.', completed: true },
    { number: 2, title: 'Activate Now', description: 'Start planning your trip right away or later. You have 24 months to complete the offer.', completed: false },
    { number: 3, title: 'Start Planning Your Trip', description: 'Choose your destinations and dates with a live travel agent.', completed: false },
    { number: 4, title: 'Booking Confirmation', description: 'Once booked, you will receive your booking confirmation.', completed: false },
    { number: 5, title: 'Enjoy Your Vacation', description: 'Relax and enjoy your vacation as a thank you for your donation.', completed: false },
  ];

  return (
    <div className="w-auto">
      <div className="fixed inset-0 z-10 bg-[#0A1E32] bg-opacity-70 max-w-[548px] mx-auto" style={{ backdropFilter: 'blur(3px)' }} />
      <div className="fixed inset-x-0 bottom-0 bg-[#0A1E32] bg-opacity-90 z-40 h-[100%] max-w-[548px] w-full mx-auto p-4 sm:p-6 overflow-hidden">
        <button className="absolute top-5 right-6 z-50 bg-transparent" onClick={onClose}>
          <img src={images.close_icon} alt="Close" className="w-4 h-4" />
        </button>
        <div className="relative text-center mb-4">
          <h2 className="text-lg font-normal text-white">Successful Purchase</h2>
        </div>
        
        <div className="flex flex-col space-y-6 overflow-y-auto h-full pb-14 scrollbar-hide">
          <RewardInfo
            title={reward.title}
            categoryName={reward.categoryName}
            imageUrl={reward.imageUrl}
          />

          <RedemptionSteps steps={steps} />

          {/* Contact Support */}
          <ContactSupport />
        </div>
      </div>
    </div>
  );
};

export default RewardPurchaseComplete;
